import * as React from 'react'
import loadable from '@loadable/component'

const Button = loadable(() => import('/src/components/Basic/Buttons/Button'))
const CtaContainer = loadable(() =>
    import('/src/components/Basic/CtaContainer')
)
const FeatureRadio = loadable(() => import('/src/components/Form/FeatureRadio'))
const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))
const LargeCard = loadable(() => import('/src/components/Cards/LargeCard'))

const StepSex = () => {
    const identifier = 'sex'

    return (
        <LargeCard className={'slick-card'}>
            <FieldSet
                align={'centered'}
                legend={`Let's start with the basics. You are a...`}
                groupName={identifier}
            >
                <FieldSpacer>
                    <FeatureRadio
                        label={'Mum'}
                        groupName={identifier}
                        name={`${identifier}Woman`}
                        value={'woman'}
                        icon={'family'}
                    />
                </FieldSpacer>

                <FieldSpacer>
                    <FeatureRadio
                        label={'Dad'}
                        groupName={identifier}
                        name={`${identifier}Man`}
                        value={'man'}
                        icon={'home'}
                    />
                </FieldSpacer>
            </FieldSet>

            <CtaContainer align={'centered'}>
                <Button type={'submit'} size={'lg'} icon={'chevron'}>
                    Next
                </Button>
            </CtaContainer>
        </LargeCard>
    )
}

export default StepSex
